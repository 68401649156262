import styled, { css } from 'styled-components'

export const Container = styled.div`
    max-width: 1600px;
    padding: 0 2rem;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }

    @media only screen and (max-width: 768px) {
        padding: 0 0;
    }
`

export const Row = styled.div`
    width: 100%;
    height: auto;
    float: left;
    box-sizing: border-box;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
    @media only screen and (max-width: 768px) {
        ${({ sm, columns }) => sm && columns && getWidthGrid(sm, columns) || sm && getWidthGrid(sm)}
        display: table;
    }
    @media only screen and (min-width: 768px) {
        ${({ md, columns }) => md && columns && getWidthGrid(md, columns) || md && getWidthGrid(md)}
    }
    @media only screen and (min-width: 1000px) {
        ${({ lg, columns }) => lg && columns && getWidthGrid(lg, columns) || lg && getWidthGrid(lg)}
    }
    
    ${({ flex }) => flex && `display: flex;`}
    ${({ center }) => center && `justify-content: center; `}

`

function getWidthGrid(value, columns = 12) {
    if (!value) return;

    let width = value / columns * 100;
    return `width: ${width}%`
}

export const Column = styled.div`
    float: left;
    padding: .25rem;
    min-height: 1px;
    box-sizing: border-box;
    align-items: center;

    ${({column}) => column && css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    `}

    @media only screen and (max-width: 768px) {
        ${({ sm, columns }) => sm && columns && getWidthGrid(sm, columns) || sm && getWidthGrid(sm)}
    }
    @media only screen and (min-width: 768px) {
        ${({ md, columns }) => md && columns && getWidthGrid(md, columns) || md && getWidthGrid(md)}
    }
    @media only screen and (min-width: 1260px) {
        ${({ lg, columns }) => lg && columns && getWidthGrid(lg, columns) || lg && getWidthGrid(lg)}
    }
    ${({ flex }) => flex && css`display: flex;`}
    ${({ center }) => center && css`justify-content: center;`}
    ${({ right }) => right && css`justify-content: flex-end;`}
    ${({ noPadding }) => noPadding && css`padding: 0rem;`}
    ${({hideOnSmall}) => hideOnSmall && css`
        @media only screen and (max-width: 768px) {
            display: none;
        }
    `}
    ${({hideOnMedium}) => hideOnMedium && css`
        @media only screen and (min-width: 768px) and (max-width: 1259px) {
            display: none;
        }
    `}
    ${({ vCenter }) => vCenter && css`display: flex; flex-direction: row; align-items: center;`}   

`
