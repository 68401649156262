import logo from './logo.svg';
import './App.css';
import lucas from './assets/Ai_lucas.jpeg'
import felipe from './assets/Ai_felipe.png'
import { Column, Container, Row } from './Grid';
import styled from 'styled-components';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Container>
          <Row>
            <Column sm={12} md={6} lg={6}>
              <Holder>
                <Image src={felipe} />
                <h1>Felipe Taguchi</h1>
	  	<p>taguchi.oftalmo@gmail.com</p>
              </Holder>
            </Column>
            <Column sm={12} md={6} lg={6}>
              <Holder>
                <Image src={lucas} />
                <h1>Lucas Orlandi</h1>
	  <p>lucas.orlandi.o@gmail.com</p>
              </Holder>
            </Column>
          </Row>

        </Container>
      </header>
    </div>
  );
}

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
 width: 100%; 
`

export default App;
